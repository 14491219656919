<template>
  <div class="p-o-programa">
    <Page
      class="bg-[#01082A] bg-microscopio bg-microscopio-posicao-3 bg-microscopio-tamanho-3 before:hidden before:h-screen before:w-screen before:fixed before:pointer-events-none before:top-0 before:left-0 md:before:block md:before:bg-blueg-md md:bg-microscopio-posicao-2-md md:bg-microscopio-tamanho-2-md"
    >
      <div class="flex flex-col items-center md:text-lg">
        <div class="text-center md:-m-3 md:mb-14">
          <router-link to="/">
            <img
              class="w-56 inline-block align-top md:w-72 xl:w-96"
              src="@/assets/novo/logo-rastrear-home.svg"
              alt="Programa RAStrear Logo"
            />
          </router-link>
        </div>
        <section class="text-white text-center pb-0 max-w-[1200px]">
          <div
            class="font-bold mb-5 mt-8 md:text-xl md:bg-[#033665] md:py-8 md:mb-0 md:rounded-t-lg md:mx-auto md:max-w-[40%]"
          >
            TERMOS DE USO
          </div>
          <div class="bg-white pt-1">
            <OAccordion class="-mt-3" :items="items">
              <template #site-rastrear>
                <p>
                  Este portal, o site do programa RAStrear (doravante “site”),
                  foi desenvolvido pela Amgen Biotecnologia do Brasil Ltda
                  (doravante “Amgen”), para uso exclusivo por profissionais da
                  área da saúde e assistentes autorizados por eles (“Usuários
                  Autorizados”)” que estejam realizando tratamento de pacientes
                  diagnosticados com Câncer Colorretal Metastático (“CCRm”) e
                  inscritos no Programa RAStrear.
                  <br />
                  <br />
                  A finalidade do programa Rastrear é auxiliar no diagnóstico do
                  Câncer Colorretal Metastático por meio do fornecimento
                  gratuito de exames do marcado KRAS (incluindo o G12C), NRAS e
                  BRAF conforme critérios definidos pela Amgen.
                  <br />
                  <br />
                  O acesso ao Portal do Programa RAStrear é restrito aos
                  Usuários Autorizados e deverá ser realizado através de login e
                  senha previamente definidos no site.
                </p>
              </template>
              <template #condicoes-de-uso-e-aceitacao>
                <p>
                  Estas Condições Gerais de Uso e Privacidade (as “Condições”)
                  regulam o funcionamento e os conteúdos inseridos no Site, bem
                  como a relação entre os Usuários Autorizados e a Amgen, em
                  decorrência da utilização do Site.
                  <br />
                  <br />
                  Ao acessar o Site você declara aceitar plenamente e sem
                  reservas todas as condições de uso aqui dispostas. Caso não
                  concorde com quaisquer dos termos destas Condições de Uso o
                  usuário deve cessar toda e qualquer forma de uso do Site,
                  abandonando-o.
                  <br />
                  <br />
                  Assim, o acesso ao Site pressupõe o aceite, por parte do
                  usuário, das presentes Condições de Uso.
                </p>
              </template>
              <template #gratuidade-do-acesso-e-servicos>
                <p>
                  O acesso e serviços oferecidos pelo Rastrear será gratuito
                  para todos os usuários, e não exige o registro prévio, no que
                  diz respeito à área de acesso público. O acesso às áreas
                  restritas depende de registro prévio, o que também não
                  acarretará quaisquer custos.
                </p>
              </template>
              <template #alteracoes-do-website-dos-servicos-e-ou-conteudo>
                <p>
                  A Amgen reserva-se o direito de alterar, unilateralmente e a
                  qualquer momento, sem aviso prévio, a apresentação,
                  configuração e os conteúdos do site, bem como estas condições.
                  Tais eventuais alterações terão como objetivo aprimorar os
                  serviços disponíveis ao usuário, segundo as exigências do
                  mercado.
                </p>
              </template>
              <template #uso-apropriado-do-site-dos-servicos-e-dos-conteudos>
                <p>
                  O Usuário Autorizado obriga-se a usar o site, os seus
                  conteúdos e os seus serviços de acordo com a legislação
                  aplicável, as presentes condições, os bons costumes e a ordem
                  pública. O usuário obriga-se, igualmente, a não usar o site,
                  os seus conteúdos e/ou serviços para fins ou efeitos ilícitos,
                  ou contrários à finalidade a que se destinam estas Condições
                  ou ainda para fins que causem ou possam causar danos a
                  direitos e interesses de terceiros. O usuário obriga-se,
                  ainda, a não utilizar o site, os seus conteúdos e/ou serviços
                  de forma que, de qualquer modo, danifique, inutilize,
                  sobrecarregue ou deteriore o site, os seus conteúdos e/ou os
                  serviços, ou impeça a normal utilização do mesmo por outros
                  usuários.
                  <br />
                  <br />
                  Todas as informações fornecidas no pelo usuário são de
                  responsabilidade exclusiva dele, sendo certo que o usuário não
                  deverá fornecer ao Rastrear dados falsos, incorretos ou que
                  não permitam a sua correta identificação, em especial no que
                  diz respeito ao acesso a áreas restritas.
                  <br />
                  <br />
                  A Amgen reserva-se o direito de impedir ou bloquear os
                  acessos, a qualquer momento, independentemente de notificação
                  prévia, aos usuários que violem estas Condições ou qualquer
                  disposição legal.
                </p>
              </template>
              <template #limitacao-de-responsabilidade-e-garantias>
                <p>
                  A AMGEN não fornece qualquer garantia quanto à ausência de
                  vírus, ameaças ou outros elementos maliciosos nos conteúdos do
                  Site, que possam causar danos ou alterações no seu equipamento
                  de informática (software e/ou hardware) ou nos documentos
                  eletrônicos e arquivos armazenados nesse sistema. A AMGEN não
                  será responsável por quaisquer danos ou prejuízos de qualquer
                  natureza, oriundos de vírus ou outros elementos maliciosos
                  inseridos nos conteúdos do Site.
                  <br />
                  <br />
                  A AMGEN envidará todos os esforços razoáveis para se
                  certificar de que sejam verídicos e corretos, bem como para
                  manter atualizados os dados e informações que constituem o
                  conteúdo do Site. Contudo, a AMGEN não será de qualquer forma
                  responsável por eventuais danos ou prejuízos, de qualquer
                  espécie, que sejam oriundos da falta de veracidade, exatidão,
                  precisão e/ou atualização do conteúdo do Site.
                  <br />
                  <br />
                  A AMGEN não é responsável, assim como não oferece nenhuma
                  garantia, qualquer que seja, ainda que relacionada à exatidão,
                  eficácia, oportunidade e adequabilidade de quaisquer
                  informações ou conteúdos obtidos de terceiros, incluindo dados
                  e informações apresentadas por usuários. A AMGEN não editará,
                  censurará ou, de outra forma, controlará qualquer conteúdo
                  fornecido em qualquer quadro de aviso ou outros fóruns
                  similares divulgados no Site, se existentes. Referidas
                  informações deverão ser, portanto, consideradas de forma
                  específica e cuidadosa, não sendo, a qualquer momento,
                  endossadas pela AMGEN.
                </p>
              </template>
              <template
                #informacoes-de-usuarios-autorizados-privacidade-e-protecao-de-dados
              >
                A fim de aprimorar a experiência de cada usuário com o Site, bem
                como para fins de análise estatística e outras formas de estudo,
                a AMGEN poderá obter informações não identificáveis sobre
                usuários, tais como: UF, ainda que o usuário não as tenha
                enviado à AMGEN por sua própria iniciativa.
                <br />
                <br />
                A AMGEN coleta dados pessoais durante o processo de
                cadastramento dos Usuários Autorizados no site e dados pessoais
                do paciente, fornecidos pelos Usuários Autorizados, com a
                finalidade de auxiliar no diagnóstico de exames do marcador
                KRAS, NRAS e BRAF.
                <br />
                <br />
                A AMGEN poderá coletar e armazenar esses dados pessoais dos
                Usuários Autorizados que se registem no programa, mediante
                solicitação de consentimento expresso do Usuário Autorizado e em
                linha com o Aviso de Privacidade do Programa RAStrear.
                <br />
                <br />
                Essas informações são necessárias para acesso da área restrita
                do Site aos Profissionais da Área da Saúde participantes do
                programa RASTREAR.
                <br />
                <br />
                Tais dados são coletadas e armazenadas, com a única e exclusiva
                finalidade de viabilizar a prestação dos serviços de suporte ao
                diagnóstico oferecidos através do programa RASTREAR, gerir o
                programa e cumprimento de exigências legais. Qualquer outra
                utilização apenas poderá ser realizada após consentimento prévio
                do proprietário do dono do dado pessoal (paciente e/ou
                profissional da área da saúde).
                <br />
                <br />
                A AMGEN garante e assegura total confidencialidade quanto aos
                dados pessoais fornecidos, garantindo
                <br />
                <br />
                também que o servidor em que estão armazenados é dotado de
                dispositivos razoáveis de segurança, necessários para evitar o
                acesso aos referidos dados por parte de terceiros não
                autorizados.
                <br />
                <br />
                Contudo, ainda que a AMGEN adote todas as medidas razoáveis e
                disponíveis para proteção contra acesso não autorizado ou
                intercepção dos referidos dados, a AMGEN não oferece garantia de
                que tais mecanismos de segurança estarão ilesos ou blindados
                contra o acesso ilegal e malicioso de terceiros não autorizados.
                <br />
                <br />
                Na hipótese remota de que ocorra o acesso não autorizado aos
                dados fornecidos e disponibilizados pelos usuários através do
                Site, apesar dos esforços da AMGEN no sentido de manter o
                ambiente online seguro, a AMGEN não será responsável pelos
                referidos acessos não autorizados, tampouco por quaisquer danos
                diretos, indiretos, especiais, acidentais ou consequenciais
                (incluindo lucros cessantes) sofridos por qualquer usuário neste
                sentido, ainda que a AMGEN tenha sido previamente informada
                sobre a possibilidade de ocorrência dos referidos danos.
                <br />
                <br />
                Na qualidade de proprietário dos dados referidos na presente
                seção, o usuário poderá solicitar à AMGEN informações sobre os
                seus dados armazenados, formas de utilização e, se necessário, a
                devolução e/ou exclusão dos referidos dados dos servidores da
                AMGEN. Para tanto, deverão entrar em contato com a AMGEN através
                do endereço
                <a target="blank" href="mailto:privacy@amgen.com"
                  >privacy@amgen.com</a
                >. Sem prejuízo, na hipótese de a AMGEN encerrar suas atividades
                e/ou desativar a webpage, adotará as providências necessárias
                para exclusão dos dados dos usuários de seus servidores.
                <br />
                <br />
              </template>
              <template #propriedade-intelectual>
                <p>
                  Todo o conteúdo do Site é de propriedade exclusiva da AMGEN
                  incluindo, sem limitações, o design gráfico, logotipos,
                  imagens, textos, diálogos, ilustrações, desenhos, fotografias,
                  audiovisuais, trailers, vídeos, animações, música, sons,
                  marcas e outros sinais distintivos de comércio, qualquer das
                  linguagens de programação utilizadas ou utilizáveis, assim
                  como todo o software de funcionamento e desenvolvimento do
                  Site, exceto aqueles conteúdos, ferramentas e outros elementos
                  que tenham sido, previamente, licenciados a favor da AMGEN
                  pelo seu legítimo titular, ou então fornecidos diretamente
                  pelos usuários do Site. A reprodução, distribuição e
                  comunicação ao público, cessão e qualquer outro ato ou
                  modalidade de exploração que não tenham sido, expressamente,
                  autorizados pelo titular dos direitos de exploração, são
                  proibidos.
                  <br />
                  <br />
                  A AMGEN não concede nenhuma licença ou autorização de uso, de
                  qualquer tipo, sobre os seus direitos de propriedade
                  intelectual e industrial ou sobre qualquer outra propriedade
                  ou direito relacionado, inserido, disponibilizado ou acessível
                  através do Site. Todas as marcas, designações, logotipos,
                  denominações ou sinais distintivos que figurem no Site são
                  marcas registadas ou sinais distintivos da propriedade da
                  AMGEN, ou foram previamente licenciados pelos seus titulares à
                  AMGEN.
                </p>
              </template>
              <template #links>
                <p>
                  O Site disponibiliza aos usuários links, que permitem a
                  visitação, por parte dos usuários, a sites de terceiros que
                  foram considerados relevantes ou interessantes pela AMGEN, com
                  base nos seus conteúdos e na relação destes com o conteúdo do
                  Site. A AMGEN não oferece, endossa ou comercializa, por si ou
                  por meio de terceiros, os serviços disponíveis nesses sites,
                  tampouco controla, exerce vigilância ou aprova os produtos,
                  serviços, conteúdos, informação, dados, arquivos ou qualquer
                  outro tipo de material apresentado por tais terceiros nos
                  sites sugeridos por meio de links no Site. A função desses
                  links é, exclusivamente, a de informar o usuário sobre a
                  existência de outras fontes de informação sobre a matéria na
                  Internet, onde o usuário poderá encontrar dados que venham a
                  acrescentar algo aos dados fornecidos no Site. A presença de
                  tais links no Site, contudo, não é e nem deve ser considerada
                  uma sugestão, convite, endosso ou recomendação de visita
                  desses sites por parte da AMGEN e, dessa forma, a AMGEN não
                  será responsável pelo resultado obtido através do acesso a
                  esses links. Note-se, também, que a AMGEN não outorga qualquer
                  garantia quanto à veracidade e precisão das informações
                  contidas em sites de terceiros, bem como quanto ao
                  cumprimento, por tais terceiros, de quaisquer normas
                  relacionadas a privacidade ou segurança da informação.
                </p>
              </template>
              <template #cookies>
                <p>
                  Um cookie é um pequeno arquivo que pode ser enviado, por
                  servidores de sites na Internet, para o disco rígido do
                  computador do usuário, sendo armazenado onde se armazenam os
                  arquivos de navegação na Internet. Os cookies permitem aos
                  sites armazenar pequenos fragmentos de informação no seu
                  computador e recuperar a informação num momento posterior. A
                  AMGEN usa dois tipos de cookies: os cookies de sessão, que são
                  arquivos temporários que são apagados da memória do computador
                  quando o usuário termina a navegação na Internet ou desliga o
                  seu computador; e os cookies residentes, que permanecem no
                  disco rígido do computador até expirarem, embora o usuário
                  possa apagá-los a qualquer momento. Contudo, é de se notar que
                  essa tecnologia não guarda dados pessoais identificáveis e não
                  é utilizada com a finalidade de coleta de qualquer tipo de
                  dados pessoais.
                </p>
              </template>
              <template #lei-e-jurisdicao>
                <p>
                  As presentes Condições, bem como qualquer relação entre o
                  usuário do Site e a AMGEN, regem-se na sua integralidade pela
                  legislação brasileira aplicável. Quaisquer conflitos judiciais
                  oriundos do uso do Site ou das presentes Condições deverão ser
                  dirimidos perante o Foro Central da Comarca de São Paulo, com
                  expressa renúncia de qualquer outro, por mais especial que
                  seja ou venha a ser.
                </p>
              </template>
            </OAccordion>
          </div>
          <div class="bg-white pt-4 pb-8 -mb-1 lg:bg-transparent">
            <small class="block text-center text-[#C4C4C4] mt-2">
              SC-BRA-CP-00322. Atualizado em Janeiro de 2023.
            </small>
          </div>
        </section>
      </div>
    </Page>
  </div>
</template>

<script>
import Page from "@/components/atoms/Page.vue";
import OAccordion from "@/components/organisms/Accordion.vue";
import { computed } from "vue";

export default {
  name: "TermosDeUso",
  components: {
    Page,
    OAccordion,
  },
  setup() {
    const items = computed(() => [
      { name: "site-rastrear", title: "1. Site Rastrear" },
      {
        name: "condicoes-de-uso-e-aceitacao",
        title: "2. Condições de uso e aceitação",
      },
      {
        name: "gratuidade-do-acesso-e-servicos",
        title: "3. Gratuidade do Acesso e Serviços.",
      },
      {
        name: "alteracoes-do-website-dos-servicos-e-ou-conteudo",
        title: "4. Alterações do Website, dos Serviços e/ou Conteúdo.",
      },
      {
        name: "uso-apropriado-do-site-dos-servicos-e-dos-conteudos",
        title: "5. Uso Apropriado do Site, dos Serviços e dos Conteúdos.",
      },
      {
        name: "limitacao-de-responsabilidade-e-garantias",
        title: "6. Limitação de Responsabilidade e Garantias.",
      },
      {
        name: "informacoes-de-usuarios-autorizados-privacidade-e-protecao-de-dados",
        title:
          "7. Informação de Usuários Autorizados,privacidade e proteção de dados.",
      },
      { name: "propriedade-intelectual", title: "8. Propriedade intelectual." },
      { name: "links", title: "9. Links." },
      { name: "cookies", title: "10. Cookies." },
      { name: "lei-e-jurisdicao", title: "11. Lei e Jurisdição." },
    ]);

    return { items };
  },
};
</script>

<style lang="postcss">
.o-accordion {
  &__item {
    @apply border-[#093C7B];
  }

  &__item__title {
    @apply !bg-[#032457];

    &::before {
      background-image: url(../../assets/arrow-down-branco.svg) !important;
    }

    &::after {
      @apply !bg-[#093C7B];
    }
  }

  &__item__content {
    @apply bg-white !text-[#727272] rounded-b-md -mt-2 text-xs text-left md:text-sm
    md:px-12 lg:px-14 xl:px-16 lg:text-base;

    ol {
      @apply list-decimal px-4;

      ol {
        list-style-type: lower-alpha;
        @apply my-4;

        ol {
          list-style-type: lower-roman;
        }
      }
    }

    ul {
      @apply list-disc px-5 my-4;
    }

    p,
    ol:not(:nth-last-child(1)) {
      @apply mb-4;
    }

    li:not(:nth-last-child(1)) {
      @apply mb-4;
    }

    & > li {
      @apply font-bold;
    }

    li {
      &::marker {
        font-weight: bold !important;
      }

      p {
        @apply font-normal;
      }
    }
  }
}
</style>
